export const templatesList = [
  "canadien",
  "canadien1",
  "canadien2",
  "canadien3",
  "gengar",
  "glalie",
  "kakuna",
  "leafish",
  "nosepass",
  "onyx",
  "pikachu",
  "rhyhorn",
] as const;

export type Template = (typeof templatesList)[number];
