import { t } from "@lingui/macro";

export const SupportSection = () => (
  <section
    id="support"
    className="relative space-y-12 bg-secondary-accent py-24 text-primary sm:py-32"
  >
    <div className="container space-y-6">
      <h1 className="text-4xl font-bold">{t`Put your expertise on display.`}</h1>

      <p className="max-w-4xl leading-loose">
        {t`When you create a custom CV design, you highlight skills or experiences that can help you stand out and make a great first impression with potential employers. A CV can be created in just a few clicks with the Start Here CV maker, even if you have no prior design experience. You can use the Start Here CV maker free of charge to create a CV online, whether you're applying for a part-time or full-time position.`}
      </p>

      <div className="flex items-center gap-x-10">
        <h1 className="text-4xl font-bold">{t`Impress recruiters with an up-to-date CV.`}</h1>
      </div>

      <p className="max-w-4xl leading-loose">
        {t`The CV should include your general contact information, references, and information about your education, experience, honors, awards, publications, presentations, relevant professional activities, and skills. Be sure to format your CV in a way that is easy to read and to check it carefully for typographical or spelling errors. With start here online CV maker, you can easily create a CV that fits your needs quickly and easily.`}
      </p>

      <div className="flex items-center gap-x-10">
        <h1 className="text-4xl font-bold">{t`Create easier with the Start Here CV maker.`}</h1>
      </div>

      <p className="max-w-4xl leading-loose">
        {t`Your CV can be designed in minutes, no creative experience is required, with Start here CV. You can choose from hundreds of CV templates to help you create your CV vision. With Adobe Express, you can create a CV from start to finish by dragging and dropping icons, graphics, and shapes. Add animated effects to any element and make it stand out on a digital CV. With Start here you'll have no trouble getting started. All you need is an idea to get started.`}
      </p>
    </div>
  </section>
);
