import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { t } from '@lingui/macro';
import { List, SquaresFour } from '@phosphor-icons/react';
import { ScrollArea, Tabs, TabsContent, TabsList, TabsTrigger } from '@reactive-resume/ui';
import { motion } from 'framer-motion';
import { Helmet } from 'react-helmet-async';
import { GridView } from './_layouts/grid';
import { ListView } from './_layouts/list';

type Layout = 'grid' | 'list';

export const ResumesPage = () => {
  const [layout, setLayout] = useState<Layout>('grid');
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [showFailedAlert, setShowFailedAlert] = useState(false);
  const location = useLocation();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    if (queryParams.get('payment') === 'success') {
      setShowSuccessAlert(true);
      setTimeout(() => setShowSuccessAlert(false), 5000); // Auto-dismiss after 5 seconds
    } else if (queryParams.get('payment') === 'failed') {
      setShowFailedAlert(true);
      setTimeout(() => setShowFailedAlert(false), 5000); // Auto-dismiss after 5 seconds
    }
  }, [location]);

  return (
    <>
      <Helmet>
        <title>
          {t`Resumes`} - {t`HetCv`}
        </title>
      </Helmet>

      {showSuccessAlert && (
        <div className="fixed top-4 right-4 w-full max-w-sm p-4 bg-green-100 border border-green-300 rounded-lg shadow-lg flex items-center space-x-4">
          <span className="text-green-800 font-semibold">Payment was successful!</span>
        </div>
      )}

      {showFailedAlert && (
        <div className="fixed top-4 right-4 w-full max-w-sm p-4 bg-red-100 border border-red-300 rounded-lg shadow-lg flex items-center space-x-4">
          <span className="text-red-800 font-semibold">Payment failed. Please try again.</span>
        </div>
      )}

      <Tabs
        value={layout}
        onValueChange={(value) => setLayout(value as Layout)}
        className="space-y-4"
      >
        <div className="flex items-center justify-between">
          <motion.h1
            initial={{ opacity: 0, x: -50 }}
            animate={{ opacity: 1, x: 0 }}
            className="text-4xl font-bold tracking-tight"
          >
            {t`Resumes`}
          </motion.h1>

          <TabsList>
            <TabsTrigger value="grid" className="size-8 p-0 sm:h-8 sm:w-auto sm:px-4">
              <SquaresFour />
              <span className="ml-2 hidden sm:block">{t`Grid`}</span>
            </TabsTrigger>
            <TabsTrigger value="list" className="size-8 p-0 sm:h-8 sm:w-auto sm:px-4">
              <List />
              <span className="ml-2 hidden sm:block">{t`List`}</span>
            </TabsTrigger>
          </TabsList>
        </div>

        <ScrollArea className="h-[calc(100vh-140px)] lg:h-[calc(100vh-88px)]">
          <TabsContent value="grid">
            <GridView />
          </TabsContent>
          <TabsContent value="list">
            <ListView />
          </TabsContent>
        </ScrollArea>
      </Tabs>
    </>
  );
};
