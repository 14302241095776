/* eslint-disable lingui/text-restrictions */
/* eslint-disable lingui/no-unlocalized-strings */

import { t } from "@lingui/macro";
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from "@reactive-resume/ui";
import { cn } from "@reactive-resume/utils";

import { useLanguages } from "@/client/services/resume/translation";

// Who are you, and why did you build HetCv?
const Question1 = () => (
  <AccordionItem value="1">
    <AccordionTrigger className="text-left leading-relaxed">{t`What is a CV?`}</AccordionTrigger>
    <AccordionContent className="prose max-w-none dark:prose-invert">
      <p>
        {t`A curriculum vitae (CV) serves as a detailed professional dossier, showcasing an individual's career trajectory, educational background, and notable achievements. It encapsulates a comprehensive overview of one's professional endeavors. Certain employers may request a CV as a component of the job application process`}
      </p>
    </AccordionContent>
  </AccordionItem>
);

// How much does it cost to run HetCv?
const Question2 = () => (
  <AccordionItem value="2">
    <AccordionTrigger className="text-left leading-relaxed">{t`Who are we?`}</AccordionTrigger>
    <AccordionContent className="prose max-w-none dark:prose-invert">
      <p>
        {t`Start Here is the first tunisian website dedicated to creating CVs online. We are proud to present to you a platform powered by state-of-the-art artificial intelligence technologies. Our CVs are perfectly tailored to various job search engines thanks to these advancements. Our CVs are regularly updated to reflect changes in the world of work and to keep your CV at the forefront of relevance. Our platform is easy to use and allows you to create your CV in minutes. We are eager to help you find the job of your dreams.`}
      </p>
    </AccordionContent>
  </AccordionItem>
);

// Other than donating, how can I support you?
const Question3 = () => (
  <AccordionItem value="3">
    <AccordionTrigger className="text-left leading-relaxed">
      {t`Why people choose Start here ?`}
    </AccordionTrigger>
    <AccordionContent className="prose max-w-none dark:prose-invert">
      <p>
        {t`In order to provide the best job search results, we have developed a platform that combines sophisticated artificial intelligence algorithms with in-depth analysis of the requirements of the most popular job search engines. Recruiter selection criteria, labor market trends, and best practices in CV writing are carefully examined by these algorithms. By using our service, you gain a considerable competitive advantage. Not only will your CV be noticed by recruiters, but it will also be perfectly optimized to rank well in the search results of online job platforms. This gives you increased visibility and enhances your chances of being contacted for professional opportunities.`}
      </p>
      <p>
        {t`Furthermore, our artificial intelligence algorithm is constantly updated to keep pace with the evolving requirements of job search engines. This ensures that your CV remains relevant and effective, even in a constantly changing professional landscape.`}
      </p>
      <p>
        {t`In summary, our use of artificial intelligence technologies allows us to offer you a superior quality CV creation service, tailored to the highest standards of job search engines. With our help, you can maximize your chances of landing the job of your dreams.`}
      </p>
    </AccordionContent>
  </AccordionItem>
);

// What languages are supported on HetCv?
const Question4 = () => {
  const { languages } = useLanguages();

  return (
    <AccordionItem value="4">
      <AccordionTrigger className="text-left leading-relaxed">{t`HOW IT WORKS ?`}</AccordionTrigger>
      <AccordionContent className="prose max-w-none dark:prose-invert">
        <h2>
          {t`Our few step process works for clients in any location and at any stage of their career.`}
        </h2>
        <h3>{t`You fill out our order form`}</h3>
        <p>
          {t`Fill out our simple online form with your career goals and your most recent CV (if you have one). This information will help us better understand your situation`}
        </p>
        <h3>{t`We assign your order`}</h3>
        <p>
          {t`We assign your order to one of our experienced CV writers you can easily create a CV that fits your needs quickly and easily.`}
        </p>
        <h3>{t`You pay your order`}</h3>
        <p>
          {t`After reviewing the first draft of your new CV, you pay your order. After payment, your writer offers up to 2 additional revisions to finalize your CV.`}
        </p>
      </AccordionContent>
    </AccordionItem>
  );
};

const Question5 = () => (
  <AccordionItem value="5">
    <AccordionTrigger className="text-left leading-relaxed">
      {t`Is it possible for me to design my own CV template?`}
    </AccordionTrigger>
    <AccordionContent className="prose max-w-none dark:prose-invert">
      <p>
        {t`Yes, any new or existing project can be transformed into a shareable template. Simply click on the three-dot menu located beside the Download button, and then follow the instructions to convert your CV design into a template.`}
      </p>
    </AccordionContent>
  </AccordionItem>
);

const Question6 = () => (
  <AccordionItem value="6">
    <AccordionTrigger className="text-left leading-relaxed">
      {t`Is Start Here CV maker user-friendly?`}
    </AccordionTrigger>
    <AccordionContent className="prose max-w-none dark:prose-invert">
      <p>
        {t`Absolutely! Start here is designed to accommodate all skill levels, offering an intuitive drag-and-drop editor to simplify the creation of your curriculum vitae. With effortless element movement, font adjustments, and more, customization to match your personal style is a breeze. Explore the myriad ways to craft stunning CVs, cover letters, and resumes professionally and aesthetically with Start here . With its seamless design process, Start here allows you to concentrate on other aspects of your job search journey."`}
      </p>
    </AccordionContent>
  </AccordionItem>
);

export const FAQSection = () => (
  <section id="faq" className="container relative py-24 sm:py-32">
    <div className="grid gap-12 lg:grid-cols-3">
      <div className="space-y-6">
        <h2 className="text-4xl font-bold">{t`Frequently Asked Questions`}</h2>

        <p className="text-base leading-loose">
          {t`Here are some questions I often get asked about CV.`}
        </p>
      </div>

      <div className="col-span-2">
        <Accordion collapsible type="single">
          <Question1 />
          <Question2 />
          <Question3 />
          <Question4 />
          <Question5 />
          <Question6 />
        </Accordion>
      </div>
    </div>
  </section>
);
