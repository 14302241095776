import { ResumeData } from ".";

export const sampleResume: ResumeData = {
  basics: {
    name: "Flen Ben Foulen",
    headline: "Creative and Innovative Web Developer",
    email: "FlenBenFoulen@gmail.com",
    phone: "(216)20 202 020 ",
    location: "Tunis",
    url: {
      label: "",
      href: "https://3web.tn/",
    },
    customFields: [],
    picture: {
      url: "https://i.ibb.co/S7TSyFg/pic-cv-men.jpg",
      size: 120,
      aspectRatio: 1,
      borderRadius: 0,
      effects: {
        hidden: false,
        border: false,
        grayscale: false,
      },
    },
  },
  sections: {
    awards: {
      id: "awards",
      name: "Awards",
      items: [],
      columns: 1,
      visible: true,
    },
    custom: {},
    skills: {
      id: "skills",
      name: "Skills",
      items: [
        {
          id: "hn0keriukh6c0ojktl9gsgjm",
          name: "Web Technologies",
          level: 0,
          visible: true,
          keywords: ["HTML5", "JavaScript", "PHP", "Python"],
          description: "Advanced",
        },
        {
          id: "r8c3y47vykausqrgmzwg5pur",
          name: "Web Frameworks",
          level: 0,
          visible: false,
          keywords: ["React.js", "Angular", "Vue.js", "Laravel", "Django"],
          description: "Intermediate",
        },
        {
          id: "b5l75aseexqv17quvqgh73fe",
          name: "Tools",
          level: 0,
          visible: true,
          keywords: ["Webpack", "Git", "Jenkins", "Docker", "JIRA"],
          description: "Intermediate",
        },
      ],
      columns: 1,
      visible: true,
    },
    summary: {
      id: "summary",
      name: "Summary",
      columns: 1,
      content:
        "<p>Innovative Web Developer with 5 years of experience in building impactful and user-friendly websites and applications. Specializes in <strong>front-end technologies</strong> and passionate about modern web standards and cutting-edge development techniques. Proven track record of leading successful projects from concept to deployment.</p>",
      visible: true,
    },
    profiles: {
      id: "profiles",
      name: "Profiles",
      items: [
        {
          id: "cnbk5f0aeqvhx69ebk7hktwd",
          url: {
            href: "https://linkedin.com/in/FlenBenFoulen",
            label: "",
          },
          icon: "linkedin",
          network: "LinkedIn",
          visible: true,
          username: "Flen Ben Foulen",
        },
        {
          id: "ukl0uecvzkgm27mlye0wazlb",
          url: {
            href: "https://github.com/FlenBenFoulen",
            label: "",
          },
          icon: "github",
          network: "GitHub",
          visible: true,
          username: "Flen Ben Foulen",
        },
        {
          id: "snslmmdlp9v7yetj2nfgkhob",
          url: {
            href: "https://www.instagram.com/",
            label: "",
          },
          icon: "instagram",
          network: "Instagram",
          visible: true,
          username: "Flen Ben Foulen",
        },
        {
          id: "ushf8uvacdkfgml4l4r7inr3",
          url: {
            href: "https://www.facebook.com/",
            label: "",
          },
          icon: "facebook",
          network: "Facebook",
          visible: true,
          username: "Flen Ben Foulen",
        },
      ],
      columns: 1,
      visible: true,
    },
    projects: {
      id: "projects",
      name: "Projects",
      items: [
        {
          id: "yw843emozcth8s1ubi1ubvlf",
          url: {
            href: "",
            label: "",
          },
          date: "",
          name: "E-Commerce Platform",
          summary:
            "<p>Led the development of a full-stack e-commerce platform, improving sales conversion by 25%.</p>",
          visible: true,
          keywords: [],
          description: "Project Lead",
        },
        {
          id: "ncxgdjjky54gh59iz2t1xi1v",
          url: {
            href: "",
            label: "",
          },
          date: "",
          name: "Interactive Dashboard",
          summary:
            "<p>Created an interactive analytics dashboard for a SaaS application, enhancing data visualization for clients.</p>",
          visible: true,
          keywords: [],
          description: "Frontend Developer",
        },
      ],
      columns: 1,
      visible: true,
    },
    education: {
      id: "education",
      name: "Education",
      items: [
        {
          id: "yo3p200zo45c6cdqc6a2vtt3",
          url: {
            href: "",
            label: "",
          },
          area: "",
          date: "August 2012 to May 2016",
          score: "",
          summary: "",
          visible: true,
          studyType: "Bachelor's in Computer Science",
          institution: "University of Tunisia",
        },
      ],
      columns: 1,
      visible: true,
    },
    interests: {
      id: "interests",
      name: "Interests",
      items: [],
      columns: 1,
      visible: true,
    },
    languages: {
      id: "languages",
      name: "Languages",
      items: [
        {
          id: "xiehe5lh6lgusro0f45pxwel",
          name: "Arabic",
          level: 4,
          visible: true,
          description: "",
        },
        {
          id: "nu98lktxk0hvx2ghs61s6z5f",
          name: "français",
          level: 3,
          visible: false,
          description: "",
        },
        {
          id: "vv3x8ct9xwybryrnw60v62dn",
          name: "English",
          level: 3,
          visible: true,
          description: "",
        },
      ],
      columns: 1,
      visible: true,
    },
    volunteer: {
      id: "volunteer",
      name: "Volunteering",
      items: [],
      columns: 1,
      visible: true,
    },
    experience: {
      id: "experience",
      name: "Experience",
      items: [
        {
          id: "lhw25d7gf32wgdfpsktf6e0x",
          url: {
            href: "https://Biatt.com",
            label: "",
          },
          date: "January 2019 to Present",
          company: "Biatt",
          summary:
            "<ul><li><p>Spearheaded the redesign of the main product website, resulting in a 40% increase in user engagement.</p></li><li><p>Developed and implemented a new responsive framework, improving cross-device compatibility.</p></li><li><p>Mentored a team of four junior developers, fostering a culture of technical excellence.</p></li></ul>",
          visible: true,
          location: "Khzema ",
          position: "Senior Web Developer",
        },
        {
          id: "r6543lil53ntrxmvel53gbtm",
          url: {
            href: "",
            label: "",
          },
          date: "June 2016 to December 2018",
          company: "TechTunis",
          summary:
            "<ul><li><p>Collaborated in a team of 10 to develop high-quality web applications using React.js and Node.js.</p></li><li><p>Managed the integration of third-party services such as Stripe for payments and Twilio for SMS services.</p></li><li><p>Optimized application performance, achieving a 30% reduction in load times.</p></li></ul>",
          visible: true,
          location: "Manar 2",
          position: "Web Developer",
        },
      ],
      columns: 1,
      visible: true,
    },
    references: {
      id: "references",
      name: "References",
      items: [
        {
          id: "f2sv5z0cce6ztjl87yuk8fak",
          url: {
            href: "",
            label: "",
          },
          name: "Available upon request",
          summary: "",
          visible: true,
          description: "",
        },
      ],
      columns: 1,
      visible: false,
    },
    publications: {
      id: "publications",
      name: "Publications",
      items: [],
      columns: 1,
      visible: true,
    },
    certifications: {
      id: "certifications",
      name: "Certifications",
      items: [
        {
          id: "spdhh9rrqi1gvj0yqnbqunlo",
          url: {
            href: "",
            label: "",
          },
          date: "2020",
          name: "Full-Stack Web Development",
          issuer: "CodeAcademy",
          summary: "",
          visible: true,
        },
        {
          id: "n838rddyqv47zexn6cxauwqp",
          url: {
            href: "",
            label: "",
          },
          date: "2019",
          name: "AWS Certified Developer",
          issuer: "Amazon Web Services",
          summary: "",
          visible: true,
        },
      ],
      columns: 1,
      visible: true,
    },
  },
  metadata: {
    template: "glalie",
    layout: [
      [
        ["summary", "experience", "education", "projects", "references"],
        [
          "profiles",
          "skills",
          "certifications",
          "interests",
          "languages",
          "awards",
          "volunteer",
          "publications",
        ],
      ],
    ],
    css: {
      value: ".section {\n\toutline: 1px solid #000;\n\toutline-offset: 4px;\n}",
      visible: false,
    },
    page: {
      margin: 14,
      format: "a4",
      options: {
        breakLine: true,
        pageNumbers: true,
      },
    },
    theme: {
      background: "#ffffff",
      text: "#000000",
      primary: "#ca8a04",
    },
    typography: {
      font: {
        family: "Merriweather",
        subset: "latin",
        variants: ["regular"],
        size: 13,
      },
      lineHeight: 1.75,
      hideIcons: false,
      underlineLinks: true,
    },
    notes: "",
  },
};
