import { t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { Helmet } from "react-helmet-async";

import { FAQSection } from "./sections/faq";
import { HeroSection } from "./sections/hero";
import { LogoCloudSection } from "./sections/logo-cloud";
import { StatisticsSection } from "./sections/statistics";
import { SupportSection } from "./sections/support";
import { TemplatesSection } from "./sections/templates";
import { TestimonialsSection } from "./sections/testimonials";

export const HomePage = () => {
  const { i18n } = useLingui();

  return (
    <main className="relative isolate bg-background">
      <Helmet prioritizeSeoTags>
        <html lang={i18n.locale} />

        <title>
          {t`HetCv`} - {t`The best Cv builder`}
        </title>

        <meta
          name="description"
          content="Notre plateforme simplifie le processus, permettant aux individus de créer des CV remarquables en quelques clics seulement. Que vous soyez un professionnel chevronné ou que vous entriez tout juste sur le marché du travail, notre site Web vous permet de vous présenter efficacement aux employeurs potentiels."
        />
      </Helmet>

      <HeroSection />
      <StatisticsSection />
      <TemplatesSection />
      <SupportSection />
      <FAQSection />
    </main>
  );
};
