import { t } from "@lingui/macro";
import { templatesList } from "@reactive-resume/utils";
import { motion } from "framer-motion";

export const TemplatesSection = () => (
  <section id="sample-resumes" className="relative py-24 sm:py-32">
    <div className="container flex flex-col gap-12 lg:min-h-[600px] lg:flex-row lg:items-start">
      <div className="space-y-4 lg:mt-16 lg:basis-96">
        <h2 className="text-4xl font-bold">{t`Templates`}</h2>

        <p className="leading-relaxed">
          {t`A curriculum vitae is an excellent way for job seekers to showcase their academic background, professional experiences, and skills. You can now personalize your CV with professional layouts and share it digitally with potential employers. Choose a template to start and discover how easy it is to create an impressive CV—no design experience required. We also create Canadian CVs that meet the specific standards and expectations of Canadian employers.`}
        </p>
      </div>

      <div className="w-full overflow-hidden lg:absolute lg:right-0 lg:max-w-[55%]">
        <motion.div
          animate={{
            x: [0, templatesList.length * 200 * -1],
            transition: {
              x: {
                duration: 30,
                repeat: Infinity,
                repeatType: "mirror",
              },
            },
          }}
          className="flex items-center gap-x-6"
        >
          {templatesList.map((template, index) => (
            <motion.a
              key={index}
              target="_blank"
              rel="noreferrer"
              href={`dashboard`}
              className="max-w-none flex-none"
              viewport={{ once: true }}
              initial={{ opacity: 0, x: -100 }}
              whileInView={{ opacity: 1, x: 0 }}
            >
              <img
                alt={template}
                loading="lazy"
                src={`/templates/jpg/${template}.jpg`}
                className=" aspect-[1/1.4142] h-[400px] rounded object-cover lg:h-[600px]"
              />
            </motion.a>
          ))}
        </motion.div>

        <div className="pointer-events-none absolute inset-y-0 left-0 hidden w-1/2 bg-gradient-to-r from-background to-transparent lg:block" />
      </div>
    </div>
  </section>
);
