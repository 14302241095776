import { t } from "@lingui/macro";
import { Input, Label, Popover, PopoverContent, PopoverTrigger } from "@reactive-resume/ui";
import { cn } from "@reactive-resume/utils";
import { HexColorPicker } from "react-colorful";

import { colors } from "@/client/constants/colors";
import { useResumeStore } from "@/client/stores/resume";

import { getSectionIcon } from "../shared/section-icon";

export const ThemeSection = () => {
  const setValue = useResumeStore((state) => state.setValue);
  const theme = useResumeStore((state) => state.resume.data.metadata.theme);

  return (
    <section id="theme" className="grid gap-y-6">
      <header className="flex items-center justify-between">
        <div className="flex items-center gap-x-4">
          {getSectionIcon("theme")}
          <h2 className="line-clamp-1 text-3xl font-bold">{t`Theme`}</h2>
        </div>
      </header>

      <main className="grid gap-y-4">
        <div className="mb-2 grid grid-cols-6 flex-wrap justify-items-center gap-y-4 @xs/right:grid-cols-9">
          {colors.map((color) => (
            <div
              key={color}
              onClick={() => {
                setValue("metadata.theme.primary", color);
              }}
              className={cn(
                "flex size-6 cursor-pointer items-center justify-center rounded-full ring-primary ring-offset-1 ring-offset-background transition-shadow hover:ring-1",
                theme.primary === color && "ring-1",
              )}
            >
              <div className="size-5 rounded-full" style={{ backgroundColor: color }} />
            </div>
          ))}
        </div>
      </main>
    </section>
  );
};
