import { motion } from "framer-motion";
import { Link } from "react-router-dom";

import { LocaleSwitch } from "@/client/components/locale-switch";
import { Logo } from "@/client/components/logo";
import { ThemeSwitch } from "@/client/components/theme-switch";

export const Header = () => (
  <motion.header
    className="fixed inset-x-0 top-0 z-20"
    initial={{ opacity: 0, y: -50 }}
    animate={{ opacity: 1, y: 0, transition: { delay: 0.3, duration: 0.3 } }}
  >
    <div className="bg-gradient-to-b from-background to-transparent py-3">
      <div className="container flex items-center justify-between">
        <Link to="/">
          <Logo size={200} />
        </Link>

        <div />
      </div>
      <div className="absolute bottom-5 right-10 lg:space-x-2">
        <LocaleSwitch />
        <ThemeSwitch />
      </div>
    </div>
  </motion.header>
);
