// Languages
export type Language = {
  id: string;
  name: string;
  locale: string;
  editorCode: string;
  progress?: number;
};

export const languages: Language[] = [
  {
    id: "ar",
    name: "Arabic",
    editorCode: "ar",
    locale: "ar-SA",
  },
  {
    id: "en",
    name: "English",
    editorCode: "en",
    locale: "en-US",
  },
  {
    id: "fr",
    name: "French",
    editorCode: "fr",
    locale: "fr-FR",
  },
];
